<script>
  import { Link, ActionText } from '@qlean/ui-kit-web-svelte'

  export let text
  export let href
</script>

{#if href === undefined}
  <ActionText size={ActionText.SIZES.SMALL}>{text}</ActionText>
{:else}
  <Link rank={Link.RANKS[1]} backdropColor={Link.BACKDROPS.DARK} {href}>
    <ActionText size={ActionText.SIZES.SMALL}>{text}</ActionText>
  </Link>
{/if}
