<script>
  import { formatPhoneHref, formatPhone } from '@qlean/web-core'
  import {
    GridContainer,
    GridColumn,
    Icon,
    Link,
    ActionText,
    Caption,
    Separator,
    WidgetHeader,
    WidgetFooter,
    StoreButton,
  } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import Services from './Services'
  import Links from './Links'

  import css from './_styles'

  const cN = classNames.bind(css)

  export let services
  export let links
  export let phone
  export let stores
  export let copyright
  export let disclaimer
  export let socialNetworkGroups
  export let withTopMargin
  export let withBottomMargin

  const disclaimerParts =
    disclaimer === undefined
      ? undefined
      : Object.keys(disclaimer.vars).reduce(
          (parts, key) => {
            return parts
              .map((part) => {
                const split = part.split(key)
                const splitPartsLength = split.length

                for (let idx = splitPartsLength - 1; idx > 0; idx--) {
                  split.splice(idx, 0, key)
                }

                return split
              })
              .flat(Infinity)
          },
          [disclaimer.template]
        )

  const dateYear = new Date().getFullYear()
  copyright = copyright.replace('$0', dateYear)
</script>

{@html Icon.spriteContent}

<Separator key={30} size={Separator.SIZES.SMALL} />
<div class={cN('footer')}>
  <WidgetHeader withMargin={withTopMargin} />
  <GridContainer class={cN('footer__content-container')}>
    {#if services !== undefined || links !== undefined}
      <GridColumn widthMiddle={10} class={cN('footer__links-block')}>
        {#if services !== undefined}
          <Services data={services} {cN} />
        {/if}
        {#if links !== undefined}
          <Links data={links} {cN} withTopMargin={services !== undefined} />
        {/if}
      </GridColumn>
    {/if}
    <GridColumn
      widthMiddle={2}
      class={cN('footer__phone-col', {
        'footer__phone-col--alone': services === undefined && links === undefined,
      })}>
      <Link rank={Link.RANKS[1]} backdropColor={Link.BACKDROPS.DARK} href={formatPhoneHref(phone)}>
        <ActionText>{formatPhone(phone)}</ActionText>
      </Link>
      {#if stores !== undefined}
        <Separator key={15} size={Separator.SIZES.SMALL} />
        <div class={cN('footer__stores')}>
          {#if stores.appStoreLink !== undefined}
            <StoreButton
              class={cN('footer__store-link')}
              name={StoreButton.NAMES.APP_STORE}
              href={stores.appStoreLink} />
          {/if}
          {#if stores.playMarketLink !== undefined}
            <StoreButton
              class={cN('footer__store-link')}
              name={StoreButton.NAMES.GOOGLE_PLAY}
              href={stores.playMarketLink} />
          {/if}
        </div>
      {/if}
    </GridColumn>

    <GridColumn>
      <Separator key={30} size={Separator.SIZES.SMALL} />
    </GridColumn>
    <GridColumn class={cN('footer__last-row')}>
      <Separator key={10} size={Separator.SIZES.SMALL} />
      {#if disclaimerParts !== undefined}
        <Caption>
          {#each disclaimerParts as part, idx}
            {#if idx % 2 === 0}
              {part}
            {:else}
              <Link
                rank={Link.RANKS[1]}
                backdropColor={Link.BACKDROPS.DARK}
                href={disclaimer.vars[part].href}>
                {disclaimer.vars[part].text}
              </Link>
            {/if}
          {/each}
        </Caption>
        <Separator key={15} size={Separator.SIZES.SMALL} />
      {/if}
      <div class={cN('footer__copy_n_socnet')}>
        <Caption>{copyright}</Caption>

        {#if socialNetworkGroups !== undefined}
          <div class={cN('footer__soc-net-container')}>
            {#if socialNetworkGroups !== undefined}
              {#each Object.entries(socialNetworkGroups) as [name, href]}
                <a target="_blank" {href} class={cN('footer__soc-net-link')}>
                  <Icon {name} key={Icon.KEYS.MEDIUM} clickable />
                </a>
              {/each}
            {/if}
          </div>
        {/if}
      </div>
    </GridColumn>
  </GridContainer>
  <WidgetFooter withMargin={withBottomMargin} />
</div>
