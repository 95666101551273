<script>
  import { GridColumn, Link, Caption, Separator } from '@qlean/ui-kit-web-svelte'

  import LinksToggler from '../LinksToggler'

  export let data
  export let cN
  export let withTopMargin = false
</script>

{#if withTopMargin}
  <GridColumn>
    <Separator key={20} size={Separator.SIZES.MEDIUM} />
  </GridColumn>
{/if}

<GridColumn widthMiddle={10} class={cN({ 'footer__links-container': !withTopMargin })}>
  {#each data as { title, list }, idx}
    {#if data[0].title !== undefined}
      {#if idx > 0}
        <Separator key={20} size={Separator.SIZES.MEDIUM} />
      {/if}
      <LinksToggler {title} {list} blockName="links" {cN} />
    {:else}
      <div class={cN('footer__links-names-container')}>
        {#if idx > 0}
          <Separator key={15} size={Separator.SIZES.MEDIUM} />
        {/if}
        {#each list as { href, text }}
          <div class={cN('footer__link-name')}>
            <Link rank={Link.RANKS[1]} backdropColor={Link.BACKDROPS.DARK} {href}>
              <Caption>{text}</Caption>
            </Link>
            <Separator
              key={20}
              size={Separator.SIZES.SMALL}
              direction={Separator.DIRECTIONS.LEFT_RIGHT} />
          </div>
        {/each}
      </div>
    {/if}
  {/each}
</GridColumn>
