<script>
  import { Link, ActionText, Icon, Caption, Separator } from '@qlean/ui-kit-web-svelte'

  import Title from '../Title'

  export let title
  export let list
  export let blockName
  export let cN

  let visible = false
  function toggleVisibility() {
    visible = !visible
  }
</script>

<div class={cN('footer__toggler-title-container')}>
  <Title {...title} />
  <div
    class={cN('footer__toggler-visibility-toggler', {
      ['footer__toggler-visibility-toggler--active']: visible,
    })}
    on:click={toggleVisibility}>
    <Icon name={Icon.NAMES.arrow} key={Icon.KEYS.MEDIUM} clickable />
  </div>
</div>

<div
  class={cN('footer__toggler-list', `footer__${blockName}-list`, {
    [`footer__toggler-list--hidden`]: !visible,
  })}>
  {#each list as { text, href }}
    <div class={cN({ [`footer__toggler-list-item`]: true })}>
      <Separator key={10} size={ActionText.SIZES.SMALL} />
      <Link
        rank={Link.RANKS[1]}
        backdropColor={Link.BACKDROPS.DARK}
        {href}
        class={cN('footer__toggler-link')}>
        <Caption>{text}</Caption>
      </Link>
    </div>
  {/each}
</div>
