<script>
  import { GridColumn, Separator } from '@qlean/ui-kit-web-svelte'

  import LinksToggler from '../LinksToggler'
  import Title from '../Title'

  export let data
  export let cN
</script>

{#if data.some(({ list }) => list !== undefined)}
  {#each data as { title, list }, idx}
    <GridColumn
      widthMiddle={2}
      class={cN('footer__service', { 'footer__service--last': idx === data.length - 1 })}>
      {#if list !== undefined}
        <LinksToggler {title} {list} blockName="service" {cN} />
      {:else}
        <Title {...title} />
      {/if}
    </GridColumn>
  {/each}
{:else}
  <div class={cN('footer__services-container')}>
    {#each data as { title }, idx}
      <div class={cN('footer__service-name')}>
        <Title {...title} />
        <Separator
          key={20}
          size={Separator.SIZES.MEDIUM}
          direction={Separator.DIRECTIONS.LEFT_RIGHT} />
      </div>
    {/each}
  </div>
{/if}
